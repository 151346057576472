import React,{ FC }  from 'react'
import { Helmet } from 'react-helmet';

import UserBox from './../components/MenuUserBox';
import MenuItems from './../components/MenuItems';
import DashTopNav from './../components/DashTopNav';
import FormIndications from './../components/FormIndications';


import './../css/input.css';        


export default function Indications() {
    return (
        <>
            <Helmet>
                <title>Indicações</title>
            </Helmet>        
           <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Indicações"/>
                    <div className="flex m-auto justify-center items-center w-100 body-scroll">
                    <FormIndications />
                    </div>
                </div>
           </main>

        </>

    );
}               

