import React from 'react';
import google from './../assets/google.svg'

interface FormRecoverPassProps {
}

const FormRecoverPass: React.FC<FormRecoverPassProps> = ({ }) => {

    return <div className="align-center">
        <h2 className='text-center'>Esqueceu sua senha?</h2>
        <p className="my-3 text-center">Digite seu email para receber um link<br className=""/> de renovação de senha:</p>
        <form>
            <input type="text" placeholder="E-mail" className="text" required/>

            <button type="submit" className="btn btn-pri w-1/1">Recuperar <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg></button>
        </form>
        <p className="login-alt"><strong>Ou login com</strong></p>
        <a className="btn btn-google"><img src={google} alt="google"/> Login com Google</a>
    </div>;

};

export default FormRecoverPass ;
