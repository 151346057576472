import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart, LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

interface DashWorkareaProps {
  title: string;
}

interface LandOwner {
  landId?: string;
  entityType: string;
  fullname: string;
  idNumber: string;
  legalName: string;
  taxIdentificationNumber: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  landName: string;
  kmzKmlPath: string;
  status: string;
  tradeName: string;
  kmzPath: string;
}

const DashWorkarea: React.FC<DashWorkareaProps> = ({ title }) => {
  const [opportunitiesCount, setOpportunitiesCount] = useState<number>(0);
  const [propertiesCount, setPropertiesCount] = useState<number>(0);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.g7ecocarbon.com.br/api/opportunity/land/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setOpportunitiesCount(response.data.length);
      } catch (error) {
        console.error('Erro ao carregar dados de oportunidades:', error);
      }
    };

    const fetchProperties = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.g7ecocarbon.com.br/api/property/land/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPropertiesCount(response.data.length);
      } catch (error) {
        console.error('Erro ao carregar dados de propriedades:', error);
      }
    };

    fetchOpportunities();
    fetchProperties();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        min: 500000,
        max: 5000000,
        ticks: {
          stepSize: 500000,
          callback: function (value: number | string) {
            return (value as number) / 1000 + 'k'; // Converte para milhares com sufixo 'k'
          },
          color: "black", // Ajusta a cor dos rótulos do eixo Y
        },
        grid: {
          color: 'rgba(0,0,0,0.05)'
        }
      },
      x: {
        type: 'category' as const,
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        ticks: {
          color: "black", // Ajusta a cor dos rótulos do eixo X
        },
        grid: {
          color: 'white'
        }
      }
    },
    plugins: {
      legend: {
        display: true, // Exibe as legendas
        position: 'top' as const,
      },
      title: {
        display: false
      }
    },
  };

  return (
    <div className="flex flex-col justify-between body-scroll">
      <div>
        <section className="flex flex-row dash-sub-nav">
          <div className="flex-auto flex items-center nav-title">
            <h3>Informações Gerais</h3>
          </div>
          <div className="flex justify-end nav-tools">
            <a href="#" className="nav-data-filter flex items-center justify-center">
              2023-2024 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"> <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /> </svg>
            </a>
          </div>
        </section>
        <section className="grid grid-rows-2 md:grid-rows-1 grid-flow-col gap-2 md:gap-4 dash-main-legend">
          <div>
            <h4>0 toneladas</h4>
            <p className="dot green">CO2 Sequestrado</p>
          </div>
          <div>
            <h4>0 </h4>
            <p className="dot blue">Créditos de Carbono</p>
          </div>
          <div>
            <h4>R$ 0,00</h4>
            <p>Conversão</p>
          </div>
          <div>
            <h4>+0%</h4>
            <p>Rentabilidade</p>
          </div>
          <div>
            <h4>+0</h4>
            <p>Indicações</p>
          </div>
        </section>

        <section className="info-geral chart-section">
          <Bar options={options} data={data} />
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 dash-widgets mt-4">
          <div className="widget-1 chart-section w-full">
            <h4 className="text-center">Clientes & Propriedades</h4>
            <div className="flex gap-2 md:gap-5">
              <div className="flex-auto p-3">
                <h5>{propertiesCount}</h5>
                <p>{propertiesCount} Total de propriedades</p>
              </div>
              <div className="flex-auto p-3">
                <h5>+0%</h5>
                <p>Variação último mês</p>
              </div>
              <div className="flex-auto p-3">
                <h5>{opportunitiesCount}</h5>
                <p>Oportunidades cadastradas</p>
              </div>
            </div>
          </div>
          <div className='widget-3 chart-section p-3 w-full'>
            <h4>Toneladas de Carbono Sequestrados Vs Tempo</h4>
            <Bar options={options2} data={data2} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default DashWorkarea;

Chart.register(LinearScale, CategoryScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      grid: {
        color: 'rgba(0,0,0,0.05)'
      },
      color: 'white'
    },
    x: {
      grid: {
        color: 'white'
      }
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
      labels: {
        font: {
          size: 10
        }
      }
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart'
    },
  },
};

const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export const data = {
  labels,
  datasets: [
    {
      label: 'CO2 Sequestrado',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: 'rgba(97, 188, 255, 1)',
      borderRadius: 5,
      maxBarThickness: 50,
    },
    {
      label: 'Créditos de Carbono',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: 'rgba(165, 226, 132, 1)',
      borderRadius: 5,
      maxBarThickness: 50,
    },
  ],
};

export const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  scaleFontColor: "#FFFFFF",
  scales: {
    y: {
      ticks: {
        color: "white",
        stepSize: 100000,
        //callback: function (tickValue: number | string) {
        //  return (tickValue as number) / 1000 + 'k'; // Formata os valores em milhares
        // },
      },
      grid: {
        color: 'rgba(255,255,255,0.5)'
      }
    },
    x: {
      ticks: {
        color: "white",
      },
      grid: {
        color: 'rgba(255,255,255,0)'
      }
    }
  },
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
      labels: {
        font: {
          size: 10
        }
      }
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart'
    },
  },
};

const labels2 = ['5', '10', '15', '20', '25', '30'];

export const data2 = {
  labels: labels2,
  datasets: [
    {
      label: 'Créditos de Carbono',
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: 'rgba(0, 73, 127, 1)',
      borderRadius: 5,
      maxBarThickness: 50,
    },
  ],
};
