import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LandOwner } from './ListOpportunity';

const UploadField = ({ label, name, accept, description, onChange, disabled }: { label: string, name: string, accept: string, description: string, onChange: (files: FileList | null) => void, disabled: boolean }) => {
    return (
        <div className="upload-field">
            <label htmlFor={name}>{label}</label>
            <input type="file" id={name} name={name} accept={accept} onChange={(e) => onChange(e.target.files)} disabled={disabled} />
            <p>{description}</p>
        </div>
    );
};

const FormOpportunity: React.FC = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<LandOwner>({
        entityType: 'Person',
        fullname: '',
        idNumber: '',
        legalName:'',
        taxIdentificationNumber:'',
        contactName: '',
        email: '',
        phoneNumber: '',
        landName: '',
        kmzKmlPath: '',
        status: '',
        tradeName: '',
        kmzPath: '',
    });
    const [successMessage, setSuccessMessage] = useState(false);
    const [token, setToken] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [kmzFileName, setKmzFileName] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        setToken(localStorage.getItem('token') || '');
    }, []);

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setSelectedFile(selectedFile);
            setFormSubmitted(true);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) {
            console.log('Formulário inválido.');
            return;
        }

        if (!selectedFile) {
            console.log('Selecione um arquivo antes de submeter o formulário.');
            return;
        }

        if (!fileUrl) {
            try {
                await handleFileUpload();
            } catch (error) {
                console.error('Erro ao fazer upload do arquivo:', error);
                return;
            }
        }
    };

    const clearForm = () => {
        setFormData({
            entityType: '',
            fullname: '',
            idNumber: '',
            legalName:'',
            taxIdentificationNumber:'',
            contactName: '',
            email: '',
            phoneNumber: '',
            landName: '',
            kmzKmlPath: '',
            status: '',
            tradeName: '',
            kmzPath: ''
        });
    };

    useEffect(() => {
        if (kmzFileName !== '') {
            submitForm();
        }
    }, [kmzFileName]);

    const handleFileUpload = async () => {
        if (selectedFile && formSubmitted) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                console.log('Enviando requisição de upload...');
                const response = await axios.post('https://api.g7ecocarbon.com.br/api/blob/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log('Progresso do upload:', percentCompleted);
                    }
                });

                const kmzPath = response.data.fileUri;
                const fileName = kmzPath.split('/').pop();
                setFormData(prevData => ({ ...prevData, kmzPath: fileName }));

                setFileUrl(kmzPath);
                setKmzFileName(fileName);
            } catch (error: any) {
                console.error('Erro ao fazer upload do arquivo:', error.response?.data?.message || error.message);
            }
        } else {
            console.log('Nenhum arquivo selecionado ou formulário não submetido.');
        }
    };

    const handleDownload = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const blob = new Blob([response.data], { type: response.data.type });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', url.split('/').pop() || 'file');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Erro ao fazer download do arquivo:', error);
        }
    };

    const handleRemoveFile = () => {
        setFileUrl('');
        setSelectedFile(null);
        setFormData(prevData => ({ ...prevData, kmzPath: '' }));
    };

    const submitForm = async () => {
        const kmzFileName = formData.kmzPath;

        const postData = {
            entityType: formData.entityType,
            fullname: formData.fullname,
            idNumber: formData.idNumber,
            legalName: formData.legalName,
            taxIdentificationNumber: formData.taxIdentificationNumber,
            emailAddress: formData.email,
            phoneNumber: formData.phoneNumber,
            landName: formData.landName,
            kmzPath: kmzFileName
        };

        console.log('Dados enviados na requisição:', postData);

        try {
            const response = await axios.post('https://api.g7ecocarbon.com.br/api/opportunity/land/', postData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setSuccessMessage(true);
            clearForm();
            console.log('Resposta da requisição:', response.data);
        } catch (error) {
            console.error('Erro ao cadastrar propriedade:', error);
        }

        setFormSubmitted(true);

        setTimeout(() => {
            setSuccessMessage(false);
            navigate('/listar-oportunidades/');
        }, 5000);
    };

    return (
        <div className="block md:flex md:flex-col justify-center body-scroll">
            <div className="">
                <div className="form-header">
                    <h2>Registro de Oportunidade</h2>
                    <p>Digite todos os campos para Registrar novas oportunidades no sistema G7 Eco Carbon.</p>
                </div>
                <form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 px-1 md:px-4 md:p-5 form-2-cols" onSubmit={handleSubmit}>
                    <div className='col-a mt-6'>
                        <div className="flex gap-4 mb-6">
                            <div className="flex items-center gap-x-3">
                                <input
                                    id="push-everything"
                                    name="entityType"
                                    type="radio"
                                    value="Person"
                                    onChange={() => person_type(setFormData, "Person")}
                                    defaultChecked={formData.entityType === 'Person'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Física</label>
                            </div>
                            <div className="flex items-center gap-x-3">
                                <input
                                    id="push-email"
                                    name="entityType"
                                    type="radio"
                                    value="Legal"
                                    onChange={() => person_type(setFormData, "Legal")}
                                    defaultChecked={formData.entityType === 'Legal'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Jurídica</label>
                            </div>
                        </div>

                        <div id="company_data" className="hidden">
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="legalName"
                                    id="legalName"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Razão Social"
                                    value={formData.legalName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="taxIdentificationNumber"
                                    id="taxIdentificationNumber"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="CNPJ"
                                    value={formData.taxIdentificationNumber}
                                    onChange={handleChange}
                                />
                                <div className='mt-5'><p className="font-bold">Dados do Representante Legal</p></div>
                            </div>
                        </div>

                        <div id="person_data">
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="fullname"
                                    id="fullname"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Nome Completo"
                                    required
                                    value={formData.fullname}
                                    onChange={handleChange}
                                />
                                {errors.fullname && <p className="text-red-500 text-xs mt-1">{errors.fullname}</p>}
                            </div>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="idNumber"
                                    id="idNumber"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="CPF"
                                    required
                                    value={formData.idNumber}
                                    onChange={handleChange}
                                />
                                {errors.idNumber && <p className="text-red-500 text-xs mt-1">{errors.idNumber}</p>}
                            </div>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="E-mail"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Whatsapp"
                                    required
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                />
                                {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
                            </div>
                        </div>
                    </div>
                    <div className='col-b mt-6'>
                        <div className='my-5'><p className="font-bold">Dados da Propriedade:</p></div>
                        <div className="mt-6 pt-2">
                            <input
                                type="text"
                                name="landName"
                                id="landName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Nome da propriedade"
                                required
                                value={formData.landName}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="mt-6">
                            <p className="block text-sm leading-6 text-gray-900">Anexe o KMZ ou KML:</p>
                            <UploadField
                                label='Selecione um arquivo'
                                name="kmzpath"
                                accept=".kmz,.kml"
                                description="Apenas arquivos *.kmz ou *.kml"
                                onChange={handleFileChange as (files: FileList | null) => void}
                                disabled={!!fileUrl}
                            />
                            {fileUrl ? (
                                <div className="flex items-center">
                                    <p className="text-gray-500"><a href="#" onClick={() => handleDownload(fileUrl)} target="_blank" rel="noopener noreferrer">Download KMZ/KML</a></p>
                                    <button onClick={handleRemoveFile} className="text-red-500 ml-2">X</button>
                                </div>
                            ) : (
                                selectedFile && <p>Arquivo selecionado: {selectedFile.name}</p>
                            )}
                        </div>
                        <div className="mt-6">
                            <p className="block text-sm leading-6 text-gray-900">Fluxo Oportunidade</p>
                            <select
                                id="stage"
                                name="stage"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                            >
                                <option>Selecione um status</option>
                                <option value="Oportunidade Registrada">Oportunidade Registrada</option>
                                <option value="Em Negociação">Em Negociação</option>
                                <option value="Aguardando consolidação">Aguardando consolidação</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-span-full mb-6">
                        <div className="mt-5 text-end">
                            <button type="submit" disabled={!formSubmitted} className={`btn btn-pri w-1/1 ${!formSubmitted ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                Registrar
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
                {successMessage && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Oportunidade cadastrada com sucesso!</strong>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

function person_type(setFormData: React.Dispatch<React.SetStateAction<LandOwner>>, entityType: string) {
    let person_data = document.getElementById('person_data') as HTMLElement;
    let company_data = document.getElementById('company_data') as HTMLElement;

    if (entityType === "Person") {
        person_data.classList.remove('hidden');
        company_data.classList.add('hidden');

        setFormData(prevData => ({
            ...prevData,
            entityType: "Person"
        }));
    } else {
        person_data.classList.remove('hidden');
        company_data.classList.remove('hidden');

        setFormData(prevData => ({
            ...prevData,
            entityType: "Legal"
        }));
    }
}

export default FormOpportunity;
