import React from 'react';

import { createRoot } from 'react-dom';
import './index.css';
import './Functions.js';

import reportWebVitals from './reportWebVitals';
import Login from "./ui/Login";
import Dashboard from "./ui/Dashboard";
import SignUp from "./ui/SignUp";
import RecoverPass from "./ui/RecoverPass";
import NewPass from "./ui/NewPass";
import Indications from "./ui/Indications";
import Properties from "./ui/Properties";
import Simulator from "./ui/Simulator";
import Calendar from "./ui/Calendar";
import PropertiesList from "./ui/PropertiesList";
import OpportunityList from "./ui/OpportunityList";
import Opportunities from "./ui/Opportunities";
import OpportunityAlter from "./ui/OpportunityAlter";
import Profile from "./ui/Profile";
import Dialog from "./components/Dialog";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Switch
} from "react-router-dom";

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = payload.exp * 1000;
      return expirationTime > Date.now();
    } catch (error) {
      console.error('Error parsing token:', error);
      return false;
    }
  }
  return false;
};

const PrivateRoute = ({ element, ...rest }) => {
  return isAuthenticated() ? (
    element
  ) : (
    <Navigate to="/" replace />

  );
};

const App = () => {



  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="cadastre-se" element={<SignUp />} />
          <Route path="recuperar-senha" element={<RecoverPass />} />
          <Route path="nova-senha" element={<NewPass />} />
          <Route path="dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="listar-propriedades" element={<PrivateRoute element={<PropertiesList />} />} />
          <Route path="listar-oportunidades" element={<PrivateRoute element={<OpportunityList />} />} />
          <Route path="/cadastrar-propriedade/:landId" component={Properties} element={<PrivateRoute element={<Properties />} />} />
          <Route path="cadastrar-oportunidade" element={<PrivateRoute element={<Opportunities />} />} />
          <Route path="/alterar-oportunidade/:landId" component={OpportunityAlter} element={<PrivateRoute element={<OpportunityAlter />} />} />
          <Route path="simulador" element={<PrivateRoute element={<Simulator />} />} />
          <Route path="indicacoes" element={<PrivateRoute element={<Indications />} />} />
          <Route path="calendario" element={<PrivateRoute element={<Calendar />} />} />
          <Route path="perfil" element={<PrivateRoute element={<Profile />} />} />
        </Routes>
        <Routes>
        <Route path="/cadastrar-propriedade/" element={<PrivateRoute element={<Properties />} />} />
        </Routes>
      </Router>
      <Dialog type="error" message="" />
      <Dialog type="success" message="" />
    </>
  );
};




// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="cadastre-se" element={<SignUp />} />
//         <Route path="recuperar-senha" element={<RecoverPass />} />
//         <Route path="dashboard" element={<Dashboard />} />
//         <Route path="cadastrar-propriedade" element={<Properties />} />
//         <Route path="listar-propriedades" element={<PropertiesList />}  />
//         <Route path="listar-oportunidades" element={<OpportunityList />}  />
//         <Route path="simulador" element={<Simulator />} />
//         <Route path="indicacoes" element={<Indications />} />
//       </Routes>
//     </Router>
//   );
// };


const root = createRoot(document.getElementById('root'));
root.render(<App />);


reportWebVitals(console.log);

