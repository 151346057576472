import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const UploadField = ({ label, name, accept, description, onChange, disabled }: { label: string, name: string, accept: string, description: string, onChange: (files: FileList | null) => void, disabled: boolean }) => {
    return (
        <div className="upload-field">
            <label htmlFor={name}>{label}</label>
            <input type="file" id={name} name={name} accept={accept} onChange={(e) => onChange(e.target.files)} disabled={disabled} />
            <p>{description}</p>
        </div>
    );
};

const FormProperty: React.FC = () => {
    const navigate = useNavigate();
    const { landId } = useParams<{ landId: string }>();
    const token = localStorage.getItem('token');

    const [formData, setFormData] = useState({
        landId: 0,
        entityType: "Person",
        fullname: "",
        idNumber: "",
        legalName: "",
        taxIdentificationNumber: "",
        emailAddress: "",
        phoneNumber: "",
        landName: "",
        landRegistry: "",
        ccitPath: "",
        itrPath: "",
        carPath: "",
        kmzPath: "",
        ownerIdPath: "",
        status: ""
    });

    const [selectedKmzFile, setSelectedKmzFile] = useState<File | null>(null);
    const [selectedCcirtFile, setSelectedCcirtFile] = useState<File | null>(null);
    const [selectedItrFile, setSelectedItrFile] = useState<File | null>(null);
    const [selectedCarFile, setSelectedCarFile] = useState<File | null>(null);
    const [selectedownerIdFile, setSelectedownerIdFile] = useState<File | null>(null);

    const [kmzFileUrl, setKmzFileUrl] = useState<string>('');
    const [ccitFileUrl, setCcittFileUrl] = useState<string>('');
    const [itrFileUrl, setItrFileUrl] = useState<string>('');
    const [carFileUrl, setCarFileUrl] = useState<string>('');
    const [ownerIdFileUrl, setownerIdFileUrl] = useState<string>('');

    const [successMessage, setSuccessMessage] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.g7ecocarbon.com.br/api/property/land/${landId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setFormData({
                    ...formData,
                    landId: response.data.landId,
                    fullname: response.data.fullname || '',
                    idNumber: response.data.idNumber || '',
                    legalName: response.data.legalName || '',
                    taxIdentificationNumber: response.data.taxIdentificationNumber || '',
                    emailAddress: response.data.emailAddress || '',
                    phoneNumber: response.data.phoneNumber || '',
                    landName: response.data.landName || '',
                    landRegistry: response.data.landRegistry || '',
                    kmzPath: response.data.kmzPath || '',
                    itrPath: response.data.itrPath || '',
                    carPath: response.data.carPath || '',
                    ownerIdPath: response.data.ownerIdPath || '',
                    ccitPath: response.data.ccitPath || '',
                    status: response.data.status || '',
                });
                setKmzFileUrl(response.data.kmzPath ? `https://api.g7ecocarbon.com.br/api/blob/download/${response.data.kmzPath}` : '');
                setCcittFileUrl(response.data.ccitPath ? `https://api.g7ecocarbon.com.br/api/blob/download/${response.data.ccitPath}` : '');
                setItrFileUrl(response.data.itrPath ? `https://api.g7ecocarbon.com.br/api/blob/download/${response.data.itrPath}` : '');
                setCarFileUrl(response.data.carPath ? `https://api.g7ecocarbon.com.br/api/blob/download/${response.data.carPath}` : '');
                setownerIdFileUrl(response.data.ownerIdPath ? `https://api.g7ecocarbon.com.br/api/blob/download/${response.data.ownerIdPath}` : '');
                updateFormState(response.data.entityType);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
            }
        };

        fetchData();
    }, [landId]);

    const updateFormState = (responseEntityType: string) => {
        if (responseEntityType === 'Person') {
            person_type('Person');
        } else if (responseEntityType === 'Legal') {
            person_type('Legal');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const fileUris = await handleFileUpload();
            const updatedFormData = {
                ...formData,
                ...fileUris, // Atualiza formData com os caminhos dos arquivos
                landId
            };
            console.log("Updated Form Data: ", updatedFormData);

            const response = await axios.patch(`https://api.g7ecocarbon.com.br/api/property/land/`, updatedFormData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log("Patch response: ", response);

            setSuccessMessage('Área atualizada com sucesso!');
            setTimeout(() => {
                setSuccessMessage('');
                navigate('/listar-propriedades');
            }, 5000);
        } catch (error) {
            console.error('Erro ao atualizar área:', error);
        }
    };

    const handleDeclarationClick = () => {
        // Mostra a modal com a mensagem inicial
        setModalContent("Será enviado no e-mail do proprietário o Termo de Intenção, que poderá ser confirmado digitalmente.");
        setShowModal(true);
    };

    const handleDeclarationSend = async () => {
        try {
            const response = await axios.post(`https://api.g7ecocarbon.com.br/api/property/land/${landId}/intent-term`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setSuccessMessage('Email enviado com sucesso');
                setModalContent('Email enviado com sucesso'); // exibe o retorno da API
                setTimeout(() => {
                    setSuccessMessage('');
                    setShowModal(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Erro ao enviar declaração:', error);
        }
    };

    const handleFileUpload = async () => {
        try {
            const uploadFile = async (file: File | null) => {
                if (!file) return '';

                const formData = new FormData();
                formData.append('file', file);

                const response = await axios.post('https://api.g7ecocarbon.com.br/api/blob/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('Upload response:', response);
                return response.data.fileUri;
            };

            const kmzFileUri = selectedKmzFile ? await uploadFile(selectedKmzFile) : formData.kmzPath;
            const ccitFileUri = selectedCcirtFile ? await uploadFile(selectedCcirtFile) : formData.ccitPath;
            const itrFileUri = selectedItrFile ? await uploadFile(selectedItrFile) : formData.itrPath;
            const carFileUri = selectedCarFile ? await uploadFile(selectedCarFile) : formData.carPath;
            const ownerIdFileUri = selectedownerIdFile ? await uploadFile(selectedownerIdFile) : formData.ownerIdPath;

            setFormData(prevData => ({
                ...prevData,
                kmzPath: kmzFileUri ? kmzFileUri.split('/').pop() : prevData.kmzPath,
                ccitPath: ccitFileUri ? ccitFileUri.split('/').pop() : prevData.ccitPath,
                itrPath: itrFileUri ? itrFileUri.split('/').pop() : prevData.itrPath,
                carPath: carFileUri ? carFileUri.split('/').pop() : prevData.carPath,
                ownerIdPath: ownerIdFileUri ? ownerIdFileUri.split('/').pop() : prevData.ownerIdPath,
            }));

            setKmzFileUrl(kmzFileUri ? `https://api.g7ecocarbon.com.br/api/blob/download/${kmzFileUri.split('/').pop()}` : kmzFileUrl);
            setCcittFileUrl(ccitFileUri ? `https://api.g7ecocarbon.com.br/api/blob/download/${ccitFileUri.split('/').pop()}` : ccitFileUrl);
            setItrFileUrl(itrFileUri ? `https://api.g7ecocarbon.com.br/api/blob/download/${itrFileUri.split('/').pop()}` : itrFileUrl);
            setCarFileUrl(carFileUri ? `https://api.g7ecocarbon.com.br/api/blob/download/${carFileUri.split('/').pop()}` : carFileUrl);
            setownerIdFileUrl(ownerIdFileUri ? `https://api.g7ecocarbon.com.br/api/blob/download/${ownerIdFileUri.split('/').pop()}` : ownerIdFileUrl);

            return {
                kmzPath: kmzFileUri ? kmzFileUri.split('/').pop() : formData.kmzPath,
                ccitPath: ccitFileUri ? ccitFileUri.split('/').pop() : formData.ccitPath,
                itrPath: itrFileUri ? itrFileUri.split('/').pop() : formData.itrPath,
                carPath: carFileUri ? carFileUri.split('/').pop() : formData.carPath,
                ownerIdPath: ownerIdFileUri ? ownerIdFileUri.split('/').pop() : formData.ownerIdPath,
            };
        } catch (error: any) {
            console.error('Erro ao fazer upload dos arquivos:', error.response?.data?.message || error.message);
            throw error;
        }
    };

    const handleKmzFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setSelectedKmzFile(selectedFile);
            setFormSubmitted(true);
        }
    };

    const handleCcirtFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedCcirtFile = files[0];
            setSelectedCcirtFile(selectedCcirtFile);
            setFormSubmitted(true);
        }
    };

    const handleItrFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedItrFile = files[0];
            setSelectedItrFile(selectedItrFile);
            setFormSubmitted(true);
        }
    };

    const handleCarFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedCarFile = files[0];
            setSelectedCarFile(selectedCarFile);
            setFormSubmitted(true);
        }
    };

    const handleownerIdFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedownerIdFile = files[0];
            setSelectedownerIdFile(selectedownerIdFile); // Corrigido
            setFormSubmitted(true);
        }
    };

    const handleRemoveFile = (type: string) => {
        if (type === 'kmz') {
            setKmzFileUrl('');
            setSelectedKmzFile(null);
            setFormData(prevData => ({ ...prevData, kmzPath: '' }));
        } else if (type === 'ccit') {
            setCcittFileUrl('');
            setSelectedCcirtFile(null);
            setFormData(prevData => ({ ...prevData, ccitPath: '' }));
        } else if (type === 'itr') {
            setItrFileUrl('');
            setSelectedItrFile(null);
            setFormData(prevData => ({ ...prevData, itrPath: '' }));
        } else if (type === 'car') {
            setCarFileUrl('');
            setSelectedCarFile(null);
            setFormData(prevData => ({ ...prevData, carPath: '' }));
        } else if (type === 'ownerId') {
            setownerIdFileUrl('');
            setSelectedownerIdFile(null);
            setFormData(prevData => ({ ...prevData, ownerIdPath: '' }));
        }
    };

    const handleDownload = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const blob = new Blob([response.data], { type: response.data.type });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', url.split('/').pop() || 'file');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Erro ao fazer download do arquivo:', error);
        }
    };

    return (
        <div className="flex flex-col justify-center body-scroll">
            <div className="form-header">
                <h2>Área<br />para emissão de contrato</h2>
                <p>Digite todos os campos para cadastrar novas <br />propriedades no sistema G7 Eco Carbon.</p>
            </div>
            <form className="grid grid-cols-2 grid-cols-md-2 gap-5 p-5 form-2-cols" onSubmit={handleSubmit}>
                <div className='col-a mt-6'>
                    <div className="flex gap-4 mb-6 ">
                        <div className="flex items-center gap-x-3">
                            <input
                                id="push-everything"
                                name="entityType"
                                type="radio"
                                value="Person"
                                checked={formData.entityType === 'Person'}
                                onChange={() => person_type("Person")}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Física</label>

                            <input
                                id="push-email"
                                name="entityType"
                                type="radio"
                                value="Legal"
                                checked={formData.entityType === 'Legal'}
                                onChange={() => person_type("Legal")}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Jurídica</label>
                        </div>
                    </div>

                    <div id="company_data" className="hidden">
                        <div className="mt-2 ">
                            <input
                                type="text"
                                name="legalName"
                                id="legalName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Razão Social"
                                required
                                value={formData.legalName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2 ">
                            <input
                                type="text"
                                name="taxIdentificationNumber"
                                id="taxIdentificationNumber"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="CNPJ"
                                required
                                value={formData.taxIdentificationNumber}
                                onChange={handleChange}
                            />
                            <div className='mt-5'><p className="font-bold">Dados do Representante Legal</p></div>
                        </div>
                    </div>

                    <div id="person_data">
                        <div className="mt-2">
                            <input
                                type="text"
                                name="fullname"
                                id="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Nome Completo"
                                required
                                value={formData.fullname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="idNumber"
                                id="cpf"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="CPF"
                                required
                                value={formData.idNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="emailAddress"
                                id="emailAddress"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="E-mail"
                                required
                                value={formData.emailAddress}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="phoneNumber"
                                id="cpf"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Telefone"
                                required
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-full mt-6">
                            <p className="block text-sm leading-6 text-gray-900">Envie um documento com foto</p>
                            <UploadField
                                label='Selecione um arquivo'
                                name="imgphoto"
                                accept=".jpeg,.png,.jpg"
                                description="Apenas arquivos *.jpeg, *.png ou *.jpg"
                                onChange={handleownerIdFileChange as (files: FileList | null) => void}
                                disabled={!!ownerIdFileUrl}
                            />
                            {ownerIdFileUrl ? (
                                <div className="flex items-center">
                                    <p className="text-gray-500"><a href="#" onClick={() => handleDownload(ownerIdFileUrl)} target="_blank" rel="noopener noreferrer">Download Fotos</a></p>
                                    <button onClick={() => handleRemoveFile('ownerId')} className="text-red-500 ml-2">X</button>
                                </div>
                            ) : (
                                selectedownerIdFile && <p>Arquivo selecionado: {selectedownerIdFile.name}</p>
                            )}
                        </div>
                        <div className="col-span-full mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Anexe o KMZ ou KML:</p>
                        <UploadField
                            label='Selecione um arquivo'
                            name="kmzpath"
                            accept=".kmz,.kml"
                            description="Apenas arquivos *.kmz ou *.kml"
                            onChange={handleKmzFileChange as (files: FileList | null) => void}
                            disabled={!!kmzFileUrl}
                        />
                        {kmzFileUrl ? (
                            <div className="flex items-center">
                                <p className="text-gray-500"><a href="#" onClick={() => handleDownload(kmzFileUrl)} target="_blank" rel="noopener noreferrer">Download KMZ/KML</a></p>
                                <button onClick={() => handleRemoveFile('kmz')} className="text-red-500 ml-2">X</button>
                            </div>
                        ) : (
                            selectedKmzFile && <p>Arquivo selecionado: {selectedKmzFile.name}</p>
                        )}
                    </div>
                    </div>
                </div>
                <div className='col-b mt-6'>
                    <div className="flex gap-4 mb-6 ">
                        <div className="flex items-center gap-x-3">
                            <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">Dados da Propriedade</label>
                        </div>
                    </div>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="landName"
                            id="landName"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Nome da Propriedade"
                            value={formData.landName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="landRegistry"
                            id="landRegistry"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Matrícula da Propriedade"
                            value={formData.landRegistry}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-span-full mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Anexe o CCIR:</p>
                        <UploadField
                            label='Selecione um arquivo'
                            name="ccitPath"
                            accept=".xlsx,.pdf,.json"
                            description="Apenas arquivos *.xlsx, *.pdf ou *.json"
                            onChange={handleCcirtFileChange as (files: FileList | null) => void}
                            disabled={!!ccitFileUrl}
                        />
                        {ccitFileUrl ? (
                            <div className="flex items-center">
                                <p className="text-gray-500"><a href="#" onClick={() => handleDownload(ccitFileUrl)} target="_blank" rel="noopener noreferrer">Download CCIR</a></p>
                                <button onClick={() => handleRemoveFile('ccit')} className="text-red-500 ml-2">X</button>
                            </div>
                        ) : (
                            selectedCcirtFile && <p>Arquivo selecionado: {selectedCcirtFile.name}</p>
                        )}
                    </div>
                    <div className="col-span-full mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Anexe o ITR:</p>
                        <UploadField
                            label='Selecione um arquivo'
                            name="itrPath"
                            accept=".xlsx,.pdf,.json"
                            description="Apenas arquivos *.xlsx, *.pdf ou *.json"
                            onChange={handleItrFileChange as (files: FileList | null) => void}
                            disabled={!!itrFileUrl}
                        />
                        {itrFileUrl ? (
                            <div className="flex items-center">
                                <p className="text-gray-500"><a href="#" onClick={() => handleDownload(itrFileUrl)} target="_blank" rel="noopener noreferrer">Download ITR</a></p>
                                <button onClick={() => handleRemoveFile('itr')} className="text-red-500 ml-2">X</button>
                            </div>
                        ) : (
                            selectedItrFile && <p>Arquivo selecionado: {selectedItrFile.name}</p>
                        )}
                    </div>
                    <div className="col-span-full mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Anexe o CAR:</p>
                        <UploadField
                            label='Selecione um arquivo'
                            name="carPath"
                            accept=".xlsx,.pdf,.json"
                            description="Apenas arquivos *.xlsx, *.pdf ou *.json"
                            onChange={handleCarFileChange as (files: FileList | null) => void}
                            disabled={!!carFileUrl}
                        />
                        {carFileUrl ? (
                            <div className="flex items-center">
                                <p className="text-gray-500"><a href="#" onClick={() => handleDownload(carFileUrl)} target="_blank" rel="noopener noreferrer">Download CAR</a></p>
                                <button onClick={() => handleRemoveFile('car')} className="text-red-500 ml-2">X</button>
                            </div>
                        ) : (
                            selectedCarFile && <p>Arquivo selecionado: {selectedCarFile.name}</p>
                        )}
                    </div>
                   
                    <div className="mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Fluxo Propriedade</p>
                        <select
                            id="stage"
                            name="stage"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={formData.status}
                            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                        >                        
                            <option value="">Selecione um status</option>
                            <option value="Aguardando Contrato">Aguardando Contrato</option>
                            <option value="Aguardando Assinatura">Aguardando Assinatura</option>
                            <option value="Iniciando Tratativas">Iniciando Tratativas</option>
                            <option value="Liquidação Operacional G7">Liquidação Operacional G7</option>
                            <option value="Enviado para Certificação">Enviado para Certificação</option>
                        </select>
                    </div>
                    <div className="mt-5 text-end flex gap-3 justify-end">
                        <button type="button" onClick={handleDeclarationClick} className="btn btn-pri flex justify-center items-center w-1/1 hover:bg-indigo-700">
                            <span className="text-center">Termo de Intenção</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            </svg>
                        </button>
                        <button type="submit" className="btn btn-pri flex justify-center items-center w-1/1 hover:bg-indigo-700">
                            Salvar
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
            {successMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">{successMessage}</strong>
                    </div>
                </div>
            )}

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-lg w-1/2">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-semibold">Termo de Intenção</h3>
                            <button onClick={() => setShowModal(false)} className="text-red-500">
                                X
                            </button>
                        </div>
                        <div className="bg-gray-100 p-4 rounded text-sm overflow-auto max-h-96">
                            {modalContent}
                        </div>
                        <div className="flex justify-end gap-2 mt-4">
                            <button className="btn btn-pri hover:bg-indigo-700" onClick={handleDeclarationSend}>Sim, Enviar</button>
                            <button className="btn btn-pri hover:bg-indigo-700" onClick={() => setShowModal(false)}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormProperty;

function person_type(entityType: string) {
    let cname = document.getElementById('legalName') as HTMLInputElement;
    let cnpj = document.getElementById('taxIdentificationNumber') as HTMLInputElement;
    let person_data = document.getElementById('person_data') as HTMLElement;
    let company_data = document.getElementById('company_data') as HTMLElement;

    if (entityType === "Person") {
        person_data.classList.remove('hidden');
        company_data.classList.add('hidden');
        cname.required = false;
        cnpj.required = false;
    } else {
        person_data.classList.remove('hidden');
        company_data.classList.remove('hidden');
        cname.required = true;
        cnpj.required = true;
    }
}
