import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import no_avatar from './../assets/no-avatar.webp';
import axios from 'axios';
import settings from './../assets/settings.svg';
import check from './../assets/check.svg';
import remove from './../assets/remove.svg'; // Importar ícone de remoção
import { useParams, useNavigate } from 'react-router-dom';

interface ListPropertyProps {
    title: string;
    user: LandOwner | null;
    onClose: () => void;
}

interface LandOwner {
    landId?: string;
    entityType: string;
    fullname: string;
    idNumber: string;
    contactName: string;
    email: string;
    phoneNumber: string;
    landName: string;
    kmzKmlPath: string;
    status: string;
    tradeName: string;
    kmzPath: string;
}

const ListProperty: React.FC<ListPropertyProps> = () => {
    const { landId } = useParams<{ landId: string }>();
    const navigate = useNavigate(); // Using useNavigate for navigation

    const [landOwners, setLandOwners] = useState<LandOwner[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedUser, setSelectedUser] = useState<LandOwner | null>(null);
    const [polygonCoordinates, setPolygonCoordinates] = useState<{ lat: number; lng: number; }[]>([]);
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number; }>({ lat: -5.208, lng: -42.751 });
    const [selectedCheckbox, setSelectedCheckbox] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição da modal
    const [userToRemove, setUserToRemove] = useState<LandOwner | null>(null); // Estado para armazenar o usuário a ser removido

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`https://api.g7ecocarbon.com.br/api/property/land/`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setLandOwners(response.data);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
            }
        };

        fetchData();
    }, [landId]);

    const itemsPerPage = 10;
    const totalPages = Math.ceil(landOwners.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = landOwners.slice(startIndex, endIndex);

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    const handleEditClick = (user: LandOwner) => {
        setSelectedUser(user);
        const landId = user.landId;
        if (landId) {
            navigate(`/cadastrar-propriedade/${landId}`);
        } else {
            console.error("ID da terra não definido:", user);
        }
    };

    const handleRemoveClick = (user: LandOwner) => {
        setUserToRemove(user); // Define o usuário a ser removido
        setShowModal(true); // Exibe a modal de confirmação
    };

    const confirmRemove = async () => {
        const landId = userToRemove?.landId;
        if (!landId) {
            console.error("ID da terra não definido:", userToRemove);
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://api.g7ecocarbon.com.br/api/property/land/${landId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json', // Mantenha o Accept simples
                }
            });
            // Atualizar a lista de proprietários de terras removendo o item deletado
            setLandOwners(landOwners.filter(owner => owner.landId !== landId));
            setShowModal(false); // Fecha a modal
            console.log(`Propriedade com ID ${landId} foi removida com sucesso.`);
        } catch (error) {
            console.error('Erro ao remover propriedade:', error);
        }
    };

    const handleCheckboxClick = useCallback(async (landId: string) => {
        const selectedUser = landOwners.find(owner => owner.landId === landId);
        if (selectedUser) {
            setSelectedUser(selectedUser);
            setSelectedCheckbox(selectedUser.landId);

            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`https://api.g7ecocarbon.com.br/api/opportunity/land/${landId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                try {
                    const { data } = response;
                    if (data && data.kmzPath) {
                        const kmlResponse = await axios.get(`https://api.g7ecocarbon.com.br/api/blob/download/${data.kmzPath}`, {
                            responseType: 'blob',
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });

                        if (kmlResponse && kmlResponse.data) {
                            const formData = new FormData();
                            formData.append('file', kmlResponse.data);
                            const kmlpolygon = await axios.post('https://api.g7ecocarbon.com.br/api/utils/convert-kml-to-polygon', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    Authorization: `Bearer ${token}`
                                }
                            });

                            const polygonformated: { lat: number; lng: number; }[] = kmlpolygon.data.map((element: any) => ({
                                lat: parseFloat(element.lat),
                                lng: parseFloat(element.lng)
                            }));

                            setPolygonCoordinates(polygonformated);

                            // Calcula o centro do polígono
                            const calculateCenter = (coordinates: { lat: number; lng: number; }[]) => {
                                if (coordinates.length === 0) {
                                    return null;
                                }

                                let latSum = 0;
                                let lngSum = 0;

                                for (let i = 0; i < coordinates.length; i++) {
                                    latSum += coordinates[i].lat;
                                    lngSum += coordinates[i].lng;
                                }

                                const latCenter = latSum / coordinates.length;
                                const lngCenter = lngSum / coordinates.length;

                                return { lat: latCenter, lng: lngCenter };
                            };

                            const center = calculateCenter(polygonformated);

                            if (center) {
                                setMapCenter(center);
                            }

                            console.log('Polygon Coordinates:', polygonformated);
                        } else {
                            console.error('Erro ao baixar o arquivo KML.');
                        }
                    } else {
                        console.error('Resposta da API inválida:', data);
                    }
                } catch (error) {
                    console.error('Erro ao obter os dados da API:', error);
                }
            } catch (error) {
                console.error('Erro ao obter os dados da API:', error);
            }
        }
    }, [landOwners]);

    return (
        <section className="flex flex-col parent-body-list">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 px-3 md:px-4 md:p-5 form-2-cols">
                <div className='col-a mt-6 '>
                    <ul className="divide-y divide-gray-100">
                        <li className="">
                            <div className="flex items-center space-x-0 rtl:space-x-reverse">
                                <div className="flex-shrink-0 p-2 bg-blue-100 rounded-l-md">
                                    <input id="push-everything" name="push-notifications" type="checkbox" className="rounded h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                <div className="flex-auto p-2 bg-green-1 items-center">
                                    Propriedade
                                </div>
                                <div className="hidden flex-none md:flex-auto md:block p-2 bg-green-2 items-center rounded-r-md">
                                    Situação
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ul className="divide-y divide-gray-100 body-list body-scroll">
                        {currentItems.map((owner) => (
                            <li className="py-3 " key={owner.landId}>
                                <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                    <div className="flex-shrink-0 p-2 ">
                                        <input
                                            id={`checkbox-${owner.landId}`}
                                            name={`checkbox-${owner.landId}`}
                                            type="checkbox"
                                            className="rounded h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            checked={selectedCheckbox === owner.landId}
                                            onChange={() => handleCheckboxClick(owner.landId!)}
                                        />
                                    </div>
                                    <div className="flex-shrink-0">
                                        <img className="w-10 h-10 rounded-full" src={no_avatar} alt="avatar imagem" />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm text-gray-900 truncate ">
                                            {owner.contactName}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate">
                                            {owner.landName}
                                        </p>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <span className='bg-status-navy rounded-full py-1 px-3'>{owner.status}</span>
                                        <a className='mx-2' href="#" onClick={() => handleEditClick(owner)}>
                                            <img src={settings} alt="settings" className="w-6 h-6" />
                                        </a>
                                        <a className='mx-2' href="#" onClick={() => handleRemoveClick(owner)}>
                                            <img src={remove} alt="remove" className="w-6 h-6" />
                                        </a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="flex justify-center mt-5">
                        {Array.from(Array(totalPages).keys()).map((page) => (
                            <button key={page} className={`mx-2 ${page + 1 === currentPage ? 'bg-[#00497f] text-[#ffffff]' : 'bg-gray-100 hover:bg-gray-300'} rounded-full py-1 px-3`} onClick={() => handlePageClick(page + 1)}>
                                {page + 1}
                            </button>
                        ))}
                    </div>
                </div>
                <div className='bg-white col-b flex flex-col justify-between gap-2 fixed inset-0 md:position-none p-5 md:p-0 md:py-5 h-100 mt-0 md:mt-1 md:gap-4 md:static hidden md:flex'>
                    <a className="absolute top-2 right-2 btn-canvas-close md:hidden">
                        <svg className=" w-7 h-7 m-2 btn-modal-close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="16" fill="#F0F0F0" />
                            <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D" />
                        </svg>
                    </a>

                    <div className="flex-auto mt-10 md:mt-0">
                        <div className="flex-auto widget-2">
                            <h4 className="text-center">{selectedUser && selectedUser.landName ? selectedUser.landName : 'Fazenda não encontrada'}</h4>
                            <LoadScript googleMapsApiKey="AIzaSyDcEA4vzKKZRQS62OCDyId9wOHMQpnau-4">
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '400px' }}
                                    zoom={10}
                                    center={mapCenter}
                                >
                                    <Polygon
                                        path={polygonCoordinates}
                                        options={{
                                            strokeColor: '#FF0000',
                                            strokeOpacity: 1.0,
                                            strokeWeight: 2,
                                            fillColor: '#FF0000',
                                            fillOpacity: 0.35
                                        }}
                                    />
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>

                    <div className="flex-auto h-100 rounded-md p-3 box-flow">
                        <h2>Fluxo das Propriedades</h2>
                        <ul>
                            <li className="set-status-con on">Aguardando Contrato</li>
                            <li className="set-status-ass">Aguardando Assinatura</li>
                            <li className="set-status-trat">Iniciando Tratativas</li>
                            <li className="set-status-op">Liquidação Operacional G7</li>
                            <li className="set-status-cert">Enviado para Certificação</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Modal de Confirmação */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-5 rounded shadow-lg w-80">
                        <h3 className="text-lg font-semibold mb-4">Confirmar Remoção</h3>
                        <p>Tem certeza de que deseja remover esta propriedade?</p>
                        <div className="flex justify-end mt-4">
                            <button
                                className="bg-red-500 text-white py-1 px-3 rounded mr-2"
                                onClick={confirmRemove}
                            >
                                Confirmar
                            </button>
                            <button
                                className="bg-gray-300 py-1 px-3 rounded"
                                onClick={() => setShowModal(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ListProperty;
