import React,{ FC }  from 'react'
import { Helmet } from 'react-helmet';
import UserBox from './../components/MenuUserBox';
import DashTopNav from './../components/DashTopNav';
import FormProfile from './../components/FormProfile';


import './../css/input.css';        


export default function Profile() {
    return (
        <>
           <Helmet>
                <title>Meus dados de cadastro</title>
           </Helmet> 
           <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Configuração de Perfil"/>
                    <div className="flex m-auto justify-center items-center w-100 body-scroll">
                        <FormProfile />
                    </div>
                </div>
           </main>
        </>

    );
}               

