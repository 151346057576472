import React, { useEffect, useState } from 'react';
import axios from 'axios';
import settings from './../assets/settings.svg';
import menu from './../assets/menu.svg';
import no_avatar from './../assets/no-avatar-blank.webp';
import MenuItems from './../components/MenuItems';

interface UserBoxProps {
    onClick: () => void;
}

const UserBox: React.FC<UserBoxProps> = ({ onClick }) => {
    const [userDetails, setUserDetails] = useState({
        name: '',
        avatarFilename: '',
        parentName: ''
    });
    const [avatarUrl, setAvatarUrl] = useState('');
    const FirstName = userDetails.name.split(' ')[0];
    const [navVisivel, setNavVisivel] = useState(false);

    const toggleNav = () => {
      setNavVisivel(!navVisivel);
    };


    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const detailsResponse = await axios.get('https://api.g7ecocarbon.com.br/api/profile', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setUserDetails({
                        name: detailsResponse.data.name,
                        avatarFilename: detailsResponse.data.avatarUrl,
                        parentName: detailsResponse.data.parent_entity
                    });
                    console.log('User Details:', detailsResponse.data);

                    if (detailsResponse.data.avatarUrl) {
                        const avatarResponse = await axios.get(`https://api.g7ecocarbon.com.br/api/blob/download/${detailsResponse.data.avatarUrl}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            },
                            responseType: 'blob',
                        });
                        console.log('Avatar Response:', avatarResponse);
                        const url = URL.createObjectURL(avatarResponse.data);
                        setAvatarUrl(url);
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados do usuário:', error);
                    setAvatarUrl(no_avatar);
                }
            }
        };

        fetchData();

        return () => {
            if (avatarUrl) {
                URL.revokeObjectURL(avatarUrl);
            }
        };
    }, []);


    

    return (
        <>

        <a href="/perfil/" className="flex menu-user-box" onClick={onClick}>
            <div className="flex-none user-avatar">
                <img src={avatarUrl || no_avatar} alt="avatar" onError={(e) => e.currentTarget.src = no_avatar} />
            </div>
            <div className="flex flex-auto items-center user-info">
                <div onClick={toggleNav} >
                    <h2>{FirstName}</h2>
                    <p>{userDetails.parentName}</p>
                </div>
            </div>
            <div className="flex flex-none gap-3 items-center user-arrow">
                <img src={settings} alt="settings" className='me-8 md:me-0' />
            </div>
        </a>
        <MenuItems navVisivel={navVisivel}/>
        <button className='btn-menu absolute top-9 right-5 bg-gray-200 rounded inline md:hidden' onClick={toggleNav}><img src={menu} alt="menu" className="w-7 h-7 p-1"/></button>

        </>
    );
};


export default UserBox;

