import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UploadField = ({ label, name, accept, description, onChange }: { label: string, name: string, accept: string, description: string, onChange: (files: FileList | null) => void }) => {
    return (
        <div className="upload-field">
            <label htmlFor={name}>{label}</label>
            <input type="file" id={name} name={name} accept={accept} onChange={(e) => onChange(e.target.files)} />
            <p>{description}</p>
        </div>
    );
};
type FormState = {
    landId: number;
    entityType: string;
    fullname: string;
    idNumber: string;
    legalName: string;
    taxIdentificationNumber: string;
    emailAddress: string;
    phoneNumber: string;
    landName: string;
    landRegistry: string;
    kmzPath: string;
    status: string;
};


const AlterOpportunity: React.FC = () => {
    const { landId } = useParams<{ landId: string }>();
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({
        landId: 0,
        entityType: "Person",
        fullname: "",
        idNumber: "",
        legalName: "",
        taxIdentificationNumber: "",
        emailAddress: "",
        phoneNumber: "",
        landName: "",
        landRegistry: "",
        kmzPath: "",
        status: "",
    });
    const navigate = useNavigate();
    const [kmzFileName, setKmzFileName] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [selectedCcirtFile, setSelectedCcirtFile] = useState<File | null>(null);
    const [selectedItrFile, setSelectedItrFile] = useState<File | null>(null);
    const [selectedCarFile, setSelectedCarFile] = useState<File | null>(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.g7ecocarbon.com.br/api/opportunity/land/${landId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log('Dados da solicitação GET:', response.data);
                setFormData({
                    ...formData,
                    landId: response.data.landId,
                    entityType: response.data.entityType || '',
                    fullname: response.data.fullname || '',
                    idNumber: response.data.idNumber || '',
                    legalName: response.data.legalName || '',
                    taxIdentificationNumber: response.data.taxIdentificationNumber || '',
                    emailAddress: response.data.emailAddress || '',
                    phoneNumber: response.data.phoneNumber || '',
                    landName: response.data.landName || '',
                    status: response.data.status || '',
                    landRegistry: response.data.landRegistry || '', 
                    kmzPath: response.data.kmzPath || '', 
                });

                updateFormState(response.data.entityType);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
            }
        };

        fetchData();
    }, [landId]);


    //função criada para alterar o status do radiobutton com base no retorno do status
    const updateFormState = (responseEntityType: string) => {
        const personRadio = document.getElementById('push-everything') as HTMLInputElement;
        const legalRadio = document.getElementById('push-email') as HTMLInputElement;

        console.log('updateFormState chamado com entityType:', responseEntityType);

        if (responseEntityType === 'Person') {
            console.log('EntityType é Person');
            person_type(setFormData, 'Person');
            personRadio.checked = true;
            legalRadio.checked = false;
            // Oculta o radio button Person
            personRadio.parentElement?.classList.add('hidden');
        } else if (responseEntityType === 'Legal') {
            console.log('EntityType é Legal');
            person_type(setFormData, 'Legal');
            legalRadio.checked = true;
            personRadio.checked = false;
            // Oculta o radio button Legal
            legalRadio.parentElement?.classList.add('hidden');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleSubmit chamado1');
        const { name, value } = e.target;
        console.log(`handleChange chamado. Name: ${name}, Value: ${value}`);
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('handleSubmit chamado');
        try {
            const updatedFormData = { ...formData, landId };
            console.log('Dados enviados para o PATCH:', updatedFormData);


                const response = await axios.patch(`https://api.g7ecocarbon.com.br/api/opportunity/land/`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('Área atualizada com sucesso!', response.data);
                setSuccessMessage(true); // Defina uma mensagem de sucesso aqui
            
        } catch (error) {
            console.error('Erro ao atualizar área:', error);
            setErrorMessage('Erro ao atualizar área. Por favor, tente novamente.'); // Defina uma mensagem de erro aqui
        }
    };

    useEffect(() => {
        if (kmzFileName !== '') {
            //handleSubmit();
        }
    }, [kmzFileName]);

    const handleFileUpload = async () => {
        if (selectedFile && formSubmitted) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                const response = await axios.post('https://api.g7ecocarbon.com.br/api/blob/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log('Progresso do upload:', percentCompleted);
                    }
                });

                const kmzPath = response.data.fileUri;
                const fileName = kmzPath.split('/').pop();
                setFormData(prevData => ({ ...prevData, kmzPath: fileName }));
                setFileUrl(kmzPath);
                setKmzFileName(fileName);
            } catch (error: any) {
                console.error('Erro ao fazer upload do arquivo:', error.response?.data?.message || error.message);
            }
        } else {
            console.log('Nenhum arquivo selecionado ou formulário não submetido.');
        }
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setSelectedFile(selectedFile);
            setIsFileSelected(true);
            setFormSubmitted(true);
        }
    };

    return (
        <div className="flex flex-col justify-center body-scroll">
            <div className="form-header">
                <h2>Alteração de Oportunidade</h2>
                <p>Digite todos os campos para Alterar novas oportunidades no sistema G7 Eco Carbon.</p>
            </div>
            <form className="grid grid-cols-2 gap-5 p-5 form-2-cols" onSubmit={handleSubmit}>
                <div className='col-a mt-6'>

                    <div className="flex gap-4 mb-6 ">
                        <div className="flex gap-4 mb-6 ">
                            <div className="flex items-center gap-x-3">
                                <input
                                    id="push-everything"
                                    name="entityType"
                                    type="radio"
                                    value="Person"
                                    checked={formData.entityType === 'Person'}
                                    onChange={() => person_type(setFormData, "Person")}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Física</label>

                                <input
                                    id="push-email"
                                    name="entityType"
                                    type="radio"
                                    value="Legal"
                                    checked={formData.entityType === 'Legal'}
                                    onChange={() => person_type(setFormData, "Legal")}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">Pessoa Jurídica</label>
                                   
                            </div>
                        </div>
                    </div>

                    <div id="company_data" className="hidden">
                        <div className="mt-2 ">
                            <input
                                type="text"
                                name="legalName"
                                id="legalName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Razão Social"

                                value={formData.legalName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2 ">
                            <input
                                type="text"
                                name="taxIdentificationNumber"
                                id="taxIdentificationNumber"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="CNPJ"

                                value={formData.taxIdentificationNumber}
                                onChange={handleChange}
                            />

                            <div className='mt-5'><p className="font-bold">Dados do Representante Legal</p></div>
                        </div>

                    </div>

                    <div id="person_data">

                        <div className="mt-2">
                            <input
                                type="text"
                                name="fullname"
                                id="fullname"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Nome Completo"
                                required
                                value={formData.fullname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="idNumber"
                                id="idNumber"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="CPF"
                                required
                                value={formData.idNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="emailAddress"
                                id="emailAddress"
                                required
                                value={formData.emailAddress}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="E-mail"
                            />
                        </div>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                required
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Whatsapp"
                            />
                        </div>
                    </div>

                </div>
                <div className='col-b mt-6'>


                    <div className='mb-2 '><p className="font-bold">Dados da Propriedade:</p></div>

                    <div className="mt-6 pt-1">
                        <input
                            type="text"
                            name="landName"
                            id="landName"
                            required
                            value={formData.landName}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Nome da propriedade"
                        />
                    </div>
                    <div className="mt-2">
                            <input type="text" name="landId" required value={formData.landId} onChange={handleChange} id="landId" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nº de Matrícula" />
                        </div>





                    <div className="mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Anexe o KMZ ou KML:</p>
                        <UploadField
                            label='Selecione um arquivo'
                            name="kmzpath"
                            accept=".kml"
                            description="Apenas arquivos *.kml"
                            onChange={handleFileChange as (files: FileList | null) => void}
                        />
                        {selectedFile && (
                            <p>Arquivo selecionado: {selectedFile.name}</p>
                        )}
                    </div>

                    <div className="mt-6">
                        <p className="block text-sm leading-6 text-gray-900">Fluxo Oportunidade</p>
                        <select
                            id="stage"
                            name="stage"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                            value={formData.status}
                        >
                            <option >Selecione um status</option>
                            <option value="Oportunidade Registrada">Oportunidade Registrada</option>
                            <option value="Em Negociação">Em Negociação</option>
                            <option value="Aguardando consolidação">Aguardando consolidação</option>
                        </select>
                    </div>


                </div>
                <div className="col-span-full mb-6">
                    {/* <p className="block text-sm leading-6 text-gray-900">Anexe o KMZ</p>
                    <div
                        onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            console.log('Arquivos arrastados:', e.dataTransfer.files);
                            handleFileChange(e.dataTransfer.files);
                        }}
                    >
                        <div className="mt-2 flex flex-row justify-start rounded-lg border border-dashed border-gray-900/25 px-6 py-2 component-upload">
                            <div className="text-center flex flex-row justify-center items-center">
                                <label htmlFor="kmzpath" className="me-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path d="M522.7 220.8c3.4-8.9 5.3-18.6 5.3-28.8c0-44.2-35.8-80-80-80c-16.5 0-31.7 5-44.4 13.5c-3.7 2.5-8.2 3.3-12.5 2.3s-8-3.8-10.2-7.6C355.9 77 309.3 48 256 48c-79.5 0-144 64.5-144 144c0 2.5 .1 4.9 .2 7.3c.4 7.1-4 13.5-10.7 15.9C51.7 232.7 16 280.2 16 336c0 70.7 57.3 128 128 128H512c61.9 0 112-50.1 112-112c0-54.2-38.5-99.4-89.6-109.8c-4.6-.9-8.6-3.9-10.9-8s-2.6-9.1-.9-13.4zM256 32c53.6 0 101 26.3 130 66.7c3.1 4.3 6 8.8 8.7 13.4c3.5-2.4 7.2-4.5 11-6.4C418.5 99.5 432.8 96 448 96c53 0 96 43 96 96c0 6.6-.7 13-1.9 19.2c-1.1 5.3-2.6 10.5-4.5 15.4c5.3 1.1 10.5 2.5 15.5 4.2C603.6 247.9 640 295.7 640 352c0 70.7-57.3 128-128 128H144C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160zM226.3 274.3l88-88c3.1-3.1 8.2-3.1 11.3 0l88 88c3.1 3.1 3.1 8.2 0 11.3c-3.1 3.1-8.2 3.1-11.3 0L336 228.7V376c0 4.4-3.6 8-8 8s-8-3.6-8-8V228.7l-47.4 56.6c-1.6 1.9-3.7 2.9-5.9 2.9c-2.1 0-4.3-.8-5.9-2.4C223.2 282.5 223.2 277.4 226.3 274.3z"></path>
                                    </svg>
                                    <p className="mt-2 font-bold text-xs text-gray-600 leading-4">Arraste ou clique aqui para selecionar um arquivo</p>
                                    <p className="mt-2 font-bold text-xs text-gray-600 leading-4">(KMZ/KML)</p>
                                </label>
                                <input type="file" id="kmzpath" name="kmzpath" accept=".kmz,.kml" className="hidden" onChange={(e) => handleFileChange(e.target.files)} />
                            </div>
                        </div>
                        {selectedFile && <p>Arquivo selecionado: {selectedFile.name}</p>}
                    </div> */}
                    <div className="mt-5 text-end">
                        <button type="submit" className="btn btn-pri w-1/1">Registrar <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg></button>
                    </div>
                </div>
            </form>
            {successMessage && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Propriedade cadastrada com sucesso!</strong>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setSuccessMessage(false)}>
                        <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Fechar</title>
                            <path d="M14.35 14.35a1 1 0 0 1-1.41 0L10 11.41l-2.94 2.94a1 1 0 1 1-1.41-1.41L8.59 10 5.65 7.06a1 1 0 0 1 1.41-1.41L10 8.59l2.94-2.94a1 1 0 0 1 1.41 1.41L11.41 10l2.94 2.94a1 1 0 0 1 0 1.41z"></path>
                        </svg>
                    </span>
                </div>
            )}
        </div>
    );
};



function person_type(setFormData: React.Dispatch<React.SetStateAction<FormState>>, entityType: string) {
    let person_data = document.getElementById('person_data') as HTMLElement;
    let company_data = document.getElementById('company_data') as HTMLElement;
    console.log('handleSubmit chamado2');
    if (entityType === "Person") {
        person_data.classList.remove('hidden');
        company_data.classList.add('hidden');

        // Definir o valor de entityType como "Person"
        setFormData(prevData => ({
            ...prevData,
            entityType: "Person"
        }));
    } else {
        person_data.classList.remove('hidden');
        company_data.classList.remove('hidden');
        console.log('handleSubmit chamado3');
        // Definir o valor de entityType como "Legal" para Pessoa Jurídica
        setFormData(prevData => ({
            ...prevData,
            entityType: "Legal"
        }));
    }
}

export default AlterOpportunity;
