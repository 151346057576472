import React from 'react';

interface DialogProps {
    type : string,
    message : string
}




const Dialog: React.FC<DialogProps> = ({type,message}) => {

    return <div className="fixed inset-0 z-20 w-100 h-100 bg-black-100 flex flex-col justify-center  items-center backdrop-white hidden" id={'modal-'+type}>
            <div className="relative modal bg-white rounded-lg shadow-lg text-center ">
                <svg className="absolute top-0 right-0 w-7 h-7 m-2 btn-modal-close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#F0F0F0"/>
                    <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D"/>
                </svg>
            {type == 'success' ? (
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#85ffb4" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/><path className="fa-primary" fill="#1fad55" d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z"/></svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#fb6565" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/><path className="fa-primary" fill="#cb1a1a" d="M280 152c0-13.3-10.7-24-24-24s-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
            )}

                <h2 className={type == 'success' ? ('text-success') : ('text-error') + ' font-bold my-3 text-modal leading-tight' }>{message}</h2>
                <div className="flex flex-row justify-center gap-3 mt-3">
                    <a href='#' className="btn btn-sec">Ok</a>
                </div>
            </div>
        </div>;

};

export default Dialog;



