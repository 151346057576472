import React, { useState } from 'react';


interface FormNewPassProps {
}

const FormNewPass: React.FC<FormNewPassProps> = ({ }) => {

    const [showErrorModal, setShowErrorModal] = useState(true);
    const [errorMessage, setErrorMessage] = useState('A senha foi alterada com sucesso!');
  
    const closeModal = () => {
        setShowErrorModal(false);
        setErrorMessage('');
      };


    return <div className="align-center">
        <h2 className='text-center'>Nova Senha</h2>
        <p className="my-3 ">Digite sua nova senha</p>
        <form>
            <input type="text" placeholder="Nova Senha" className="text" required/>
            <input type="text" placeholder="Confirme a nova senha" className="text" required/>
            <button type="submit" className="btn btn-pri w-1/1">Salvar <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg></button>
        </form>


        {showErrorModal && (
            <div className="fixed inset-0 z-20 w-100 h-100 bg-black-100 flex flex-col justify-center  items-center backdrop-white" onClick={closeModal}   >
                <div className="relative modal bg-white rounded-lg shadow-lg text-center">
                    <svg className="absolute top-0 right-0 w-7 h-7 m-2 btn-modal-close close" onClick={closeModal} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F0F0F0" />
                        <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#85ffb4" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/><path className="fa-primary" fill="#1fad55" d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z"/></svg>
                    <p className='text-success font-bold my-3 text-modal leading-tight'>{errorMessage}</p>
                </div>
            </div>
            )};

    </div>;
};

export default FormNewPass ;
