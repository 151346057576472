import React from 'react';

import remove from './../assets/remove.svg';
import axios from 'axios';

interface FormSimulatorProps {
    title: string;
}

const FormSimulator: React.FC<FormSimulatorProps> = () => {
    return (
        <div className="flex flex-col body-scroll">
            <form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 p-1 mb-5 md:pb-1 form-2-cols">
                <div className="col-a mt-6">
                    <div className="mt-2">
                        <label htmlFor="ha">Quantidade de Hectares</label>
                        <input
                            type="tel"
                            name="ha"
                            id="ha"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Ex: 3600"
                        />
                    </div>

                    <div className="grid mt-4 grid-cols-2 gap-2">
                        <div className="">
                            <label htmlFor="vegetation">Tipo de Bioma</label>
                            <select
                                name="vegetation"
                                id="vegetation"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                <option value=""></option>
                                <option value="Amazônia">Amazônia</option>
                                <option value="Caatinga">Caatinga</option>
                                <option value="Cerrado">Cerrado</option>
                                <option value="Mata Atlântica">Mata Atlântica</option>
                                <option value="Pampas">Pampas</option>
                                <option value="Pantanal">Pantanal</option>
                            </select>
                        </div>

                        <div className="">
                            <label htmlFor="type">Tipo de Cultura</label>
                            <select
                                name="type"
                                id="type"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                <option value=""></option>
                                <option value="Algodão">Algodão</option>
                                <option value="Arroz">Arroz</option>
                                <option value="Cana-de-açucar">Cana-de-açucar</option>
                                <option value="Erva-mate">Erva-mate</option>
                                <option value="Eucalipto">Eucalipto</option>
                                <option value="Milho Safra">Milho Safra</option>
                                <option value="Milho Safrinha">Milho Safrinha</option>
                                <option value="Pinus">Pinus</option>
                                <option value="Soja">Soja</option>
                                <option value="Café">Café</option>
                                <option value="Trigo">Trigo</option>
                                <option value="Sorgo">Sorgo</option>
                                <option value="Laranja">Laranja</option>
                                <option value="Pastagens">Pastagens</option>
                                <option value="Mata Nativa">Mata Nativa</option>
                            </select>
                        </div>
                    </div>

                    <div className="mt-4 hidden">
                        <label htmlFor="time">Tempo de cultura / ano</label>
                        <select
                            name="time"
                            id="time"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value="1">1 ano</option>
                            <option value="2">2 anos</option>
                            <option value="3">3 anos</option>
                            <option value="4">4 anos</option>
                            <option value="5">5 anos</option>
                            <option value="6">6 anos</option>
                            <option value="7">7 anos</option>
                            <option value="8">8 anos</option>
                            <option value="9">9 anos</option>
                            <option value="10">10 anos</option>
                        </select>
                    </div>
                </div>
                <div className="col-b mt-0 md:mt-6">
                    <div className="flex flex-col gap-4 ">
                        <div className="flex items-center gap-x-3 mt-6 pt-3 hidden">
                            <input
                                id="invest_type_1"
                                name="invest_type"
                                type="radio"
                                value="1"
                                defaultChecked
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                            />
                            <label htmlFor="invest_type_1" className="block">
                                Com investimento próprio
                            </label>
                        </div>
                        <div className="flex items-center gap-x-3 hidden">
                            <input
                                id="invest_type_2"
                                name="invest_type"
                                type="radio"
                                value="2"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="invest_type_2" className="block">
                                Com investimneto terceiro
                            </label>
                        </div>
                        <div className="flex items-center gap-x-3 hidden">
                            <input
                                id="invest_type_3"
                                name="invest_type"
                                type="radio"
                                value="3"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="invest_type_3" className="block">
                                Financiamento G7
                            </label>
                        </div>
                        <div className="flex items-center gap-x-3 mt-6">
                            <a href="#" onClick={simumlate} className="btn btn-pri w-1/1 ">
                                Simular{' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </form>

            <form className="grid grid-cols-1 gap-2 md:gap-5 p-1 mb-5 md:pb-1 form-2-cols pt-6">
                <div className="text-blue-900 text-xxs">
                    O Simulador Inteligente de Cálculo de Crédito de Carbono fornecido pela G7 EcoCarbon é uma
                    ferramenta educacional e informativa projetada para auxiliar em estimativas, porém, os resultados
                    apresentados podem não refletir com precisão a mensuração de créditos de carbono das propriedades. O
                    Simulador não garante a precisão, integridade ou atualidade das informações fornecidas, e os
                    usuários são aconselhados a consultar o time G7 antes de tomar decisões financeiras, legais ou
                    ambientais com base nos resultados apresentados. A G7 EcoCarbon não se responsabiliza por quaisquer
                    expectativas criadas decorrentes do uso do simulador, e os usuários concordam em isentar a empresa
                    de qualquer responsabilidade por danos relacionados ao uso ou desempenho.
                </div>
            </form>

            <form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 p-1 md:p-5 form-2-cols">
                <div className="col-a mt-6 divide-y divide-gray-200">
                    <div className="flex text-blue-900 font-bold pb-2 text-xm">
                        <div className="flex w-20">Qtd de ha</div>
                        <div className="flex-auto">Cultura</div>
                        <div className="flex-auto">Bioma</div>
                        <div className="flex w-5">&nbsp;</div>
                    </div>

                    <div className="divide-y divide-gray-200" id="calc-history"></div>
                </div>

                <div className="col-b mt-2 md:mt-6">
                    <div className="flex flex-row border-b mb-2 font-medium rounded-md simultor-result text-lg max-w-lg m-auto">
                        <div className="flex-auto">
                            <p className="mb-4 text-xs leading-loose">
                                <strong>Valor do Investimento (USD):</strong>
                                <br />
                                <b id="invest_total_usd">$ 0,00</b>
                            </p>
                        </div>
                        <div className="flex-auto">
                            <p className="mb-4 text-xs leading-loose">
                                <strong>Valor do Investimento:</strong>
                                <br />
                                <b id="invest_total">R$ 0,00</b>
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col font-medium rounded-md simultor-result text-lg max-w-lg m-auto pb-5">
                        <div className="flex-auto text-green-300">
                            <p className="mb-4 text-xs">
                                <strong>Valor Anual:</strong>
                            </p>
                        </div>
                        <div className="flex flex-row gap-3 border-b pb-2">
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Sequestro CO2 (toneladas)
                                    <br />
                                    <b className="text-bold text-gray-500" id="seq-years1">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    C.C.
                                    <br />
                                    <b className="text-bold text-gray-500" id="cc-years1">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-40">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Total
                                    <br />
                                    <b className="text-bold text-gray-500" id="years1">
                                        0,00
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col font-medium rounded-md simultor-result text-lg max-w-lg m-auto">
                        <div className="flex-auto result-label">
                            <p className="mb-4 text-xs">
                                <strong>Retroativo de 10 anos:</strong>
                            </p>
                        </div>
                        <div className="flex flex-row gap-3 border-b pb-2">
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Sequestro CO2 (toneladas)
                                    <br />
                                    <b className="text-bold text-gray-500" id="seq-years10">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    C.C.
                                    <br />
                                    <b className="text-bold text-gray-500" id="cc-years10">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-40">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Total
                                    <br />
                                    <b className="text-bold text-gray-500" id="years10">
                                        0,00
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col font-medium rounded-md simultor-result text-lg max-w-lg m-auto py-5">
                        <div className="flex-auto">
                            <p className="mb-4 text-xs">
                                <strong className="text-cyan-400">Previsão de 20 anos:</strong>
                            </p>
                        </div>
                        <div className="flex flex-row gap-3 border-b pb-2">
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Sequestro CO2 (toneladas)
                                    <br />
                                    <b className="text-bold text-gray-500" id="seq-years20">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    C.C.
                                    <br />
                                    <b className="text-bold text-gray-500" id="cc-years20">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-40">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Total
                                    <br />
                                    <b className="text-bold text-gray-500" id="years20">
                                        0,00
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col font-medium rounded-md simultor-result text-lg max-w-lg m-auto">
                        <div className="flex-auto ">
                            <p className="mb-4 text-xs">
                                <strong className="text-blue-800">Previsão total (10+20 anos)</strong>
                            </p>
                        </div>
                        <div className="flex flex-row gap-3 border-b pb-2">
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Sequestro CO2 (toneladas)
                                    <br />
                                    <b className="text-bold text-gray-500" id="seq-years30">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-20">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    C.C.
                                    <br />
                                    <b className="text-bold text-gray-500" id="cc-years30">
                                        0
                                    </b>
                                </div>
                            </div>
                            <div className="flex-auto w-40">
                                <div className="text-xxs text-blue-900 mb-0 leading-normal	">
                                    Total
                                    <br />
                                    <b className="text-bold text-gray-500" id="years30">
                                        0,00
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col text-end max-w-lg mt-2 m-auto">
                        Cotação dolar: {moneyf(BRLUSD)}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormSimulator;

const URLAPI = 'https://economia.awesomeapi.com.br/json/last/USD-BRL';
var BRLUSD: number;
let jsonUSD = axios
    .get(URLAPI)
    .then((response) => {
        // Handle response data here
        console.log(response.data.USDBRL.bid);
        BRLUSD = response.data.USDBRL.bid;
    })
    .catch((error) => {
        // Handle errors here
        BRLUSD = 5;
        console.error('Error fetching data:', error);
    });

if (isNaN(BRLUSD)) BRLUSD = 5.51;

const TonCO2Price: number = 8;
const PriceHa: number = 5;

function simumlate() {
    let qtyha = document.getElementById('ha') as HTMLInputElement;
    let qtyha_num: number = parseFloat(qtyha.value);
    let typeCultur = document.getElementById('type') as HTMLSelectElement;
    let isInvest = document.querySelector('input[name=invest_type]:checked') as HTMLInputElement;
    let vegetation = document.getElementById('vegetation') as HTMLSelectElement;

    if (isInvest == null) return false;

    let qtyhaVal: number = parseFloat(qtyha.value);
    let typeCulturVal: number = parseFloat(typeCultur.value);

    if (vegetation.value != '' && typeCultur.value != '' && qtyha_num > 0) {
        let bioma: number = 0;
        switch (vegetation.value) {
            case 'Amazônia':
                bioma = 18;
                break;
            case 'Caatinga':
                bioma = 8;
                break;
            case 'Cerrado':
                bioma = 12;
                break;
            case 'Mata Atlântica':
                bioma = 12;
                break;
            case 'Pampas':
                bioma = 10;
                break;
            case 'Pantanal':
                bioma = 8;
                break;
            default:
                bioma = 18;
                break;
        }

        let cultura: number = 0;
        switch (typeCultur.value) {
            case 'Algodão':
                cultura = 8;
                break;
            case 'Arroz':
                cultura = 6;
                break;
            case 'Cana-de-açucar':
                cultura = 9;
                break;
            case 'Erva-mate':
                cultura = 10;
                break;
            case 'Eucalipto Pinus':
                cultura = 10;
                break;
            case 'Milho Safra':
                cultura = 8;
                break;
            case 'Milho Safrinha':
                cultura = 4;
                break;
            case 'Pinus':
                cultura = 10;
                break;
            case 'Soja':
                cultura = 8;
                break;
            case 'Café':
                cultura = 10;
                break;
            case 'Trigo':
                cultura = 8;
                break;
            case 'Sorgo':
                cultura = 4;
                break;
            case 'Laranja':
                cultura = 10;
                break;
            case 'Pastagens':
                cultura = 4;
                break;
            case 'Mata Nativa':
                cultura = 0;
                break;
            default:
                cultura = 8;
                break;
        }

        if (typeCultur.value == 'Mata Nativa') {
            typeCulturVal = bioma;
        } else {
            typeCulturVal = cultura;
        }

        let calcHistory = document.getElementById('calc-history') as HTMLElement;
        let newElement = document.createElement('div');
        newElement.classList.add('flex', 'py-2');

        newElement.innerHTML = `
            <div class="flex w-20 total-years" data-ha="${qtyha.value}" data-ton="${typeCulturVal}">${qtyha.value}</div>
            <div class="flex-auto">${typeCultur.options[typeCultur.selectedIndex].text}</div>
            <div class="flex-auto">${vegetation.options[vegetation.selectedIndex].text}</div>
            <a class="flex w-5 btn-remove" attr="btn_remove" href="#"><img src="${remove}" alt="remove" class="w-[1.5rem]" /></a>
        `;

        calcHistory.appendChild(newElement);

        // Adiciona evento de clique para remover item e atualizar os cálculos
        const removeButton = newElement.querySelector('.btn-remove') as HTMLAnchorElement;
        removeButton.addEventListener('click', function () {
            newElement.remove();
            updateCalculations();
        });

        updateCalculations();
    }

    vegetation.value = '';
    typeCultur.value = '';
    qtyha.value = '';
}

function updateCalculations() {
    const AllHas = document.querySelectorAll('.total-years');
    let sumTotal: number = 0;
    let totalInvest: number = 0;
    let SeqTonCO2Year: number = 0;
    let CcTonCO2Year: number = 0;

    AllHas.forEach(function (item) {
        const value: any = item.getAttribute('data-ha');
        const ton: any = item.getAttribute('data-ton');

        SeqTonCO2Year += parseFloat(value) * parseFloat(ton);
        CcTonCO2Year += (parseFloat(ton) / 2) * parseFloat(value);
        totalInvest += parseFloat(value) * PriceHa;

        sumTotal += ((CcTonCO2Year / 2) * TonCO2Price) * parseFloat(value);
    });

    const isInvest = document.querySelector('input[name=invest_type]:checked') as HTMLInputElement;

    let TonCO2YearBRL: number;
    if (isInvest && isInvest.value == '1') {
        TonCO2YearBRL = sumTotal * BRLUSD * 0.5;
    } else {
        TonCO2YearBRL = sumTotal * BRLUSD * 0.3;
    }

    const Seqyears1: number = SeqTonCO2Year;
    const Seqyears10: number = SeqTonCO2Year * 10;
    const Seqyears20: number = SeqTonCO2Year * 20;
    const Seqyears30: number = SeqTonCO2Year * 30;

    const Ccyears1: number = CcTonCO2Year;
    const Ccyears10: number = CcTonCO2Year * 10;
    const Ccyears20: number = CcTonCO2Year * 20;
    const Ccyears30: number = CcTonCO2Year * 30;

    const years1: number = Ccyears1 * TonCO2Price;
    const years10: number = Ccyears10 * TonCO2Price;
    const years20: number = Ccyears20 * TonCO2Price;
    const years30: number = Ccyears30 * TonCO2Price;

    const investTotal = document.getElementById('invest_total') as HTMLElement;
    const investTotal_USD = document.getElementById('invest_total_usd') as HTMLElement;

    investTotal.innerHTML = moneyf(totalInvest * BRLUSD).toString();
    investTotal_USD.innerHTML = moneyf(totalInvest, 'USD').toString();

    const ResultYears1 = document.getElementById('years1') as HTMLElement;
    const ResultYears10 = document.getElementById('years10') as HTMLElement;
    const ResultYears20 = document.getElementById('years20') as HTMLElement;
    const ResultYears30 = document.getElementById('years30') as HTMLElement;

    ResultYears1.innerHTML = `${moneyf(years1, 'USD')}<br />${moneyf(years1 * BRLUSD)}`;
    ResultYears10.innerHTML = `${moneyf(years10, 'USD')}<br />${moneyf(years10 * BRLUSD)}`;
    ResultYears20.innerHTML = `${moneyf(years20, 'USD')}<br />${moneyf(years20 * BRLUSD)}`;
    ResultYears30.innerHTML = `${moneyf(years30, 'USD')}<br />${moneyf(years30 * BRLUSD)}`;

    const SeqResultYears1 = document.getElementById('seq-years1') as HTMLElement;
    const SeqResultYears10 = document.getElementById('seq-years10') as HTMLElement;
    const SeqResultYears20 = document.getElementById('seq-years20') as HTMLElement;
    const SeqResultYears30 = document.getElementById('seq-years30') as HTMLElement;

    SeqResultYears1.innerHTML = Seqyears1.toString();
    SeqResultYears10.innerHTML = Seqyears10.toString();
    SeqResultYears20.innerHTML = Seqyears20.toString();
    SeqResultYears30.innerHTML = Seqyears30.toString();

    const CcResultYears1 = document.getElementById('cc-years1') as HTMLElement;
    const CcResultYears10 = document.getElementById('cc-years10') as HTMLElement;
    const CcResultYears20 = document.getElementById('cc-years20') as HTMLElement;
    const CcResultYears30 = document.getElementById('cc-years30') as HTMLElement;

    CcResultYears1.innerHTML = Ccyears1.toString();
    CcResultYears10.innerHTML = Ccyears10.toString();
    CcResultYears20.innerHTML = Ccyears20.toString();
    CcResultYears30.innerHTML = Ccyears30.toString();
}

function moneyf(num: number, type: string = 'BRL') {
    // Format the price above to USD using the locale, style, and currency.
    let USDollar = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: type,
    });

    return USDollar.format(num);
}
