import React from 'react';
import { Helmet } from 'react-helmet';

import UserBox from './../components/MenuUserBox';
import MenuItems from './../components/MenuItems';
import DashTopNav from './../components/DashTopNav';
import DashWorkarea from './../components/DashWorkarea';

export default function Dashboard() {
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Menu Principal" />
                    <DashWorkarea />
                </div>
            </main>
        </>
    );
}
