import React, { useState,useEffect } from 'react';
import no_avatar from './../assets/no-avatar-blank.webp';
import axios from 'axios';

import cpfCheck from 'cpf-check';
///import UploadField from './UploadField';
const email = localStorage.getItem('email');

const UploadField = ({ label, name, accept, description, onChange }: { label: string, name: string, accept: string, description: string, onChange: (files: FileList | null) => void }) => {
    return (
        <div className="upload-field">
            <label htmlFor={name}>{label}</label>
            <input type="file" id={name} name={name} accept={accept} onChange={(e) => onChange(e.target.files)} />
            <p>{description}</p>
        </div>
    );
};


interface FormProfileProps { }


const FormProfile: React.FC<FormProfileProps> = () => {
    const token = localStorage.getItem('token');


    const [formSubmitted, setFormSubmitted] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    
    const [userDetails, setUserDetails] = useState({
        fullname: '',
        avatarPath: ''
        });
    const [avatarUrl, setAvatarUrl] = useState('');
    const FirstName = userDetails.fullname.split(' ')[0];
    const [navVisivel, setNavVisivel] = useState(false);


    const [formData, setFormData] = useState({
        fullname: '',
        avatarPath: '',
    });


    const toggleNav = () => {
      setNavVisivel(!navVisivel);
    };


    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');

            if (token) {
                try {
                    const detailsResponse = await axios.get('https://api.g7ecocarbon.com.br/api/profile', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setUserDetails({
                        fullname: detailsResponse.data.name,
                        avatarPath: detailsResponse.data.avatarUrl,
                    });
                    setFormData({ ...formData,fullname:  detailsResponse.data.name,avatarPath: detailsResponse.data.avatarUrl});
                    console.log('User Details:', detailsResponse.data);

                    if (detailsResponse.data.avatarUrl) {
                        const avatarResponse = await axios.get(`https://api.g7ecocarbon.com.br/api/blob/download/${detailsResponse.data.avatarUrl}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            },
                            responseType: 'blob',
                        });
                        console.log('Avatar Response:', avatarResponse);
                        const url = URL.createObjectURL(avatarResponse.data);
                        setAvatarUrl(url);
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados do usuário:', error);
                    setAvatarUrl(no_avatar);
                }
            }
        };

        fetchData();

        return () => {
            if (avatarUrl) {
                URL.revokeObjectURL(avatarUrl);
            }
        };
    }, []);


    // Função handleFileChange adicionada
    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const selectedFile = files[0]; // Acessando o arquivo individual dentro do FileList
            console.log('Arquivo selecionado:', selectedFile.name); // Verificando se o arquivo está sendo corretamente selecionado
            //setFormData({ ...formData,avatarPath: selectedFile.name});
            setSelectedFile(selectedFile); // Definindo o arquivo selecionado no estado
            console.log('Form submitted:', formSubmitted); // Verificando se formSubmitted é verdadeiro
            setFormSubmitted(true); // Marcando o formulário como submetido
            console.log('Form submitted after setting:', formSubmitted); // Verificando se formSubmitted é verdadeiro após a atualização
        }
    };    


    const handleFileUpload = async () => {

        try {
            const fileUploadPromises: Promise<string>[] = [];

            // Função para fazer upload de um arquivo e salvar o retorno como uma string
            const uploadAndSaveAsString = async (file: File) => { // Adicionando tipo explícito para o parâmetro file
                const formData = new FormData();
                formData.append('file', file);

                const response = await axios.post('https://api.g7ecocarbon.com.br/api/blob/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`
                    }
                });

                return JSON.stringify(response.data); // Salvando o retorno como uma string
            };

            // Adicionando cada upload à lista de promessas
            fileUploadPromises.push(uploadAndSaveAsString(selectedFile));

            console.log('Enviando requisições de upload...');

            // Executando os uploads em série
            const responses = await Promise.all(fileUploadPromises);

            // Extraindo as respostas e atribuindo-as às variáveis correspondentes
            const filePathResponse = responses[0];

            // Função para extrair o nome do arquivo do URL JSON
            const extractFileNameFromUrl = (url: string) => {
                try {
                    const parsedUrl = JSON.parse(url); // Converter a string JSON para objeto
                    const fileUri = parsedUrl.fileUri; // Extrair a URL do arquivo
                    const fileName = fileUri.split('/').pop(); // Extrair o nome do arquivo da URL

                    return fileName;
                } catch (error) {
                    console.error('Erro ao extrair nome do arquivo do URL JSON:', error);
                    return ''; // Retornar uma string vazia em caso de erro
                }
            };

            
            // Extrair o nome do arquivo de cada resposta
            const FileName = extractFileNameFromUrl(filePathResponse);

            // Salvando os nomes dos arquivos nas variáveis de estado correspondentes
            setFormData(formData => ({
                ...formData,
                avatarPath: FileName || ""
            }));

            setFormData({ ...formData,avatarPath:FileName});

            formData.avatarPath = FileName;
            

            console.log('Caminho do Avatar:', FileName);

        } catch (error: any) {
            console.error('Erro ao fazer upload dos arquivos:', error.response?.data?.message || error.message);
        }
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // if (formData.password !== formData.confirmPassword) {
        //     alert('As senhas não coincidem.');
        //     return;
        // }

        await handleFileUpload();
        

        console.log('formData.avatarPath:', formData.avatarPath);



        try {
            await axios.post('https://api.g7ecocarbon.com.br/api/profile', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            alert('Cadastro realizado com sucesso!');
            window.location.reload();

            // setFormData({
            //     fullname: '',
            //     avatarPath:'' 
            // });
        } catch (error) {
            console.error('Erro ao cadastrar:', error);
            alert('Erro ao cadastrar. Por favor, tente novamente.');
        }
    };






    
    return (<section className="block md:flex md:flex-col md:justify-center body-scroll">

<form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 px-1 md:px-4 md:p-5 form-2-cols"  onSubmit={handleSubmit}>
    <div className='col-a mt-6 md:flex md:flex-col  '>

        <div className="max-w-md m-auto">




                <div className="mb-6">
                        <img src={avatarUrl || no_avatar} alt="avatar" onError={(e) => e.currentTarget.src = no_avatar} className="w-[200px] h-[200px] rounded-full mx-auto object-cover	" />
                        <p className="block text-sm leading-6 text-gray-900">Foto de Perfil</p>
                        

                        <UploadField
                            label='Selecione um arquivo'
                            name="avatar"
                            accept=".jpg,.png"
                            description="Apenas arquivos *.jpg ou *.png"
                            onChange={handleFileChange as (files: FileList | null) => void}
                        />
                        {selectedFile && (
                            <p>Arquivo selecionado: {selectedFile.name}</p>
                        )}
                </div>                

                <input
                    type="text"
                    placeholder="Nome Completo"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    required
                />
                {/* <input
                    type="text"
                    placeholder="CPF"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="idNumber"
                    value={formData.idNumber}
                    onChange={handleChange}
                />
                <input
                    type="tel"
                    placeholder="Telefone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="phone"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    placeholder="Empresa"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="company"
                    value={formData.email}
                    onChange={handleChange}
                />
                <hr className="my-3"/> */}
                <h2 className="d-block mt-6 font-bold text-xl text-[#00497f]"><small>Dados de Login</small></h2>
                <input
                    type="text"
                    placeholder="E-mail"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    readOnly
                    required
                />

                {/* <input
                    type="password"
                    placeholder="Senha"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                <input
                    type="password"
                    placeholder="Repita a Senha"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                /> */}

                    <div className="mt-5 text-end sticky bottom-0 p-3 border-t bg-white ">
                        <button type="submit" className="btn btn-pri w-1/1">Salvar <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg></button>
                    </div>
                </div>
            </div>
            <div className='col-b mt-6 flex justify-between'>

                <div className="flex-auto widget-5">
                    </div>
                </div>
            </form>
        </section>);
}

export default FormProfile;