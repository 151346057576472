import React from 'react';

interface ComponentCalendarProps {
    type : string,
    message : string
}




const ComponentCalendar: React.FC<ComponentCalendarProps> = ({type,message}) => {

    const email = localStorage.getItem('email');

    return  <div className="flex flex-col justify-between body-scroll">
                <div className="px-4 ">
                    <iframe src={'https://calendar.google.com/calendar/embed?src='+email+'&ctz=America%2FSao_Paulo'} className="component-calendar" width="800" height="600"></iframe>
                </div>
            </div>;

};

export default ComponentCalendar;



