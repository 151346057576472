import React,{ FC }  from 'react'
import { Helmet } from 'react-helmet';
import Dialog from "./../components/Dialog";
import UserBox from './../components/MenuUserBox';
import MenuItems from './../components/MenuItems';
import DashTopNav from './../components/DashTopNav';
import FormOpportunity from './../components/FormOpportunity';


import './../css/input.css';        


export default function Opportunities() {
    return (
        <>
            <Helmet>
                <title>Oportunidade</title>
            </Helmet>    
            <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Cadastrar Oportunidade"/>
                    <FormOpportunity />
                </div>
           </main>
        </>

    );
}               

