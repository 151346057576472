import React, { useState } from 'react';
import google from './../assets/google.svg';
import { useNavigate } from 'react-router-dom';
import Dialog from './../components/Dialog';

const FormLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.g7ecocarbon.com.br/api/access/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Response:', data);
        if (data.token) {
          localStorage.setItem("email",email);
          localStorage.setItem('token', data.token);
          navigate('/dashboard');
        } else {
          console.error('Token not found in response:', data);
        }
      } else if (response.status === 401) {
        const errorText = await response.text(); // Obter o texto da resposta
        setShowErrorModal(true);
        setErrorMessage(`Erro ao processar solicitação: ${errorText} Credenciais inválidas.`);
      } else {
        const errorData = await response.json();
        setShowErrorModal(true);
        if (errorData && errorData.message) {
          setErrorMessage(`Erro ao processar solicitação: ${errorData.message}`);
        } else {
          setErrorMessage('Erro ao processar solicitação. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Erro ao processar solicitação:', error);
      setShowErrorModal(true);
      setErrorMessage('Erro ao processar solicitação. Por favor, tente novamente.');

    }
  };
  

    const closeModal = () => {
      setShowErrorModal(false);
      setErrorMessage('');
    };

  return (
    <div className="align-center">
      <h2>Login</h2>
      <p>Entre com seu e-mail e sua senha</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            placeholder="Seu e-mail"
            className="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            placeholder="Sua senha"
            className="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <p className="grid grid-cols-2 form-actions">
          <label htmlFor="remember" className="hover">
            <input type="checkbox" name="remember" id="remember" value="remember-me" /> Lembrar de mim
          </label>
          <a href="recuperar-senha" className="hover">Esqueceu a senha?</a>
        </p>
        <button type="submit" className="btn btn-pri w-1/1">Acessar
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
          </svg>
        </button>
      </form>
      <p className="login-alt"><strong>Ou login com</strong></p>
      <a className="btn btn-google">
        <img src={google} alt="google" /> Login com Google
      </a>

      {showErrorModal && (
        <div className="fixed inset-0 z-20 w-100 h-100 bg-black-100 flex flex-col justify-center  items-center backdrop-white"  onClick={closeModal} >
          <div className="relative modal bg-white rounded-lg shadow-lg text-center">
                <svg className="absolute top-0 right-0 w-7 h-7 m-2 btn-modal-close close" onClick={closeModal} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#F0F0F0"/>
                    <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D"/>
                </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#fb6565" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/><path className="fa-primary" fill="#cb1a1a" d="M280 152c0-13.3-10.7-24-24-24s-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>               
            <p className='text-error font-bold my-3 text-modal leading-tight'>{errorMessage}</p>
          </div>
        </div>
      )}


      
      
    </div>
  );
};

export default FormLogin;
