import React from 'react'
import { Helmet } from 'react-helmet';
import logo from './../assets/Marca.svg'
import FormRecoverPass from './../components/FormRecoverPass';

import './../css/input.css';        


export default function SignUp() {
    return (
        <>
           <Helmet>
                <title>Recuperar senha</title>
           </Helmet> 
           <div className="md:flex main-login p-2 sm:p-0 overflow-auto	md:overflow-hidden	">
                <div className="flex-auto  p-0 md:p-4">
                    <div className=" grid grid-cols-1 gap-1 md:gap-4 content-between welcome-area">
                        <a href="/"><img src={logo} className="login-logo" alt="logo"/></a>
                        <div className="hidden md:block">
                            <h1 class="text-sm leading-8">Thinking on the Future</h1>
                            <p>Uma plataforma exclusiva para você simular a quantidade de sequestro de carbono, cadastrar propriedades e acompanhar suas áreas.</p>
                        </div>
                    </div>
                </div>
                <div className="flex-none p-1 ">
                    <div className="login-area  flex items-center">
                        <FormRecoverPass />
                    </div>
                </div>
           </div>
        </>

    );
}               
