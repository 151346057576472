import React,{ FC }  from 'react'
import { Helmet } from 'react-helmet';
import UserBox from './../components/MenuUserBox';
import MenuItems from './../components/MenuItems';
import DashTopNav from './../components/DashTopNav';
import FormProperty from './../components/FormProperty';


import './../css/input.css';        


export default function Properties() {
    return (
        <>
           <Helmet>
                <title>Propriedade</title>
           </Helmet> 
           <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Cadastro de Propriedade"/>
                    <FormProperty />
                </div>
           </main>
        </>

    );
}               

