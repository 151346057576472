import React,{ FC }  from 'react'
import { Helmet } from 'react-helmet';
import UserBox from './../components/MenuUserBox';
import MenuItems from './../components/MenuItems';
import DashTopNav from './../components/DashTopNav';
import ListProperty from './../components/ListProperty';


import './../css/input.css';        


export default function PropertiesList() {
    return (
        <>
           <Helmet>
                <title>Lista de Propriedades</title>
           </Helmet> 
           <main className="block md:flex">
                <div className="block md:flex-none p-1 main-menu">
                    <UserBox />
                </div>
                <div className="block md:flex-auto p-4 work-area">
                    <DashTopNav title="Listar Propriedades"/>
                    <ListProperty />
                </div>
           </main>
        </>

    );
}               

