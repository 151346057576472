import React, { useState } from 'react';
import google from './../assets/google.svg';
import axios from 'axios';
import cpfCheck from 'cpf-check';

interface FormSignUpProps { }

const FormSignUp: React.FC<FormSignUpProps> = () => {

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
  
    const [formData, setFormData] = useState({
        fullName: '',
        idNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {

            setShowErrorModal(true);
            setErrorMessage('As senhas não coincidem');
      
            return;
        }

        try {
            await axios.post('https://api.g7ecocarbon.com.br/api/access/register', {
                idNumber: formData.idNumber,
                fullName: formData.fullName,
                email: formData.email,
                password: formData.password
            });

            setShowSuccessModal(true);
            setSuccessMessage('Cadastro realizado com sucesso!');

            //alert('Cadastro realizado com sucesso!');

            setFormData({
                fullName: '',
                idNumber: '',
                email: '',
                password: '',
                confirmPassword: ''
            });
        } catch (error) {
            console.error('Erro ao cadastrar:', error);
            
            setShowErrorModal(true);
            setErrorMessage('Erro ao cadastrar. Por favor, tente novamente.');
        }
    };


    const closeModal = () => {
        setShowErrorModal(false);
        setShowSuccessModal(false);
        setErrorMessage('');
        setSuccessMessage('');
      };

    return (
        <div className="align-center ">
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Nome Completo"
                    className="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                />
                <h2 className="d-block mb-3"><small>Dados de Login</small></h2>
                <input
                    type="text"
                    placeholder="E-mail"
                    className="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <input
                    type="password"
                    placeholder="Senha"
                    className="text"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                <input
                    type="password"
                    placeholder="Repita a Senha"
                    className="text"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                />

                <button type="submit" className="btn btn-pri w-1/1">
                    Cadastre-se{' '}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </form>
            <p className="login-alt">
                <strong>Ou login com</strong>
            </p>
            <a className="btn btn-google">
                <img src={google} alt="google" /> Login com Google
            </a>

            {showErrorModal && (
                <div className="fixed inset-0 z-20 w-100 h-100 bg-black-100 flex flex-col justify-center  items-center backdrop-white"  onClick={closeModal} >
                <div className="relative modal bg-white rounded-lg shadow-lg text-center">
                        <svg className="absolute top-0 right-0 w-7 h-7 m-2 btn-modal-close close" onClick={closeModal} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="32" height="32" rx="16" fill="#F0F0F0"/>
                            <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D"/>
                        </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#fb6565" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/><path className="fa-primary" fill="#cb1a1a" d="M280 152c0-13.3-10.7-24-24-24s-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152zM256 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>               
                    <p className='text-error font-bold my-3 text-modal leading-tight'>{errorMessage}</p>
                </div>
                </div>
            )}

            {showSuccessModal && (
            <div className="fixed inset-0 z-20 w-100 h-100 bg-black-100 flex flex-col justify-center  items-center backdrop-white" onClick={closeModal}   >
                <div className="relative modal bg-white rounded-lg shadow-lg text-center">
                    <svg className="absolute top-0 right-0 w-7 h-7 m-2 btn-modal-close close" onClick={closeModal} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#F0F0F0" />
                        <path d="M20 12.7L19.3 12L16 15.3L12.7 12L12 12.7L15.3 16L12 19.3L12.7 20L16 16.7L19.3 20L20 19.3L16.7 16L20 12.7Z" fill="#19161D" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-8 h-8 m-auto'><path className="fa-secondary" opacity=".4" fill="#85ffb4" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/><path className="fa-primary" fill="#1fad55" d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z"/></svg>
                    <p className='text-success font-bold my-3 text-modal leading-tight'>{successMessage}</p>
                </div>
            </div>
            )};



        </div>
    );
};

export default FormSignUp;
