import React from 'react';

import no_avatar from './../assets/no_avatar.webp'


interface FormIndicationsProps {
    title: string;
}

const FormIndications: React.FC<FormIndicationsProps> = () => {

    return <section className="block md:flex md:flex-col md:justify-center body-scroll">

    <form className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 px-1 md:px-4 md:p-5 form-2-cols">
        <div className='col-a mt-6 md:flex md:flex-col  '>
    
            <div className="max-w-md m-auto">
    

                    <h2 className="title-indications">Indicações Parceiros <strong>G7 EcoCarbon</strong></h2>
                    <p className="desc-indications">Digite todos os campos para cadastrar as suas indicações de Licenciados na plataforma.</p>

                    <div className="mt-2">
                        <input type="text" name="" id="name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Nome Completo Responsavel*" required/>
                    </div>
                    <div className="mt-2">
                        <input type="text" name="name" id="name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="CNPJ*" required/>
                    </div>
                    <div className="mt-2">
                        <input type="text" name="name" id="name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Razão Social*" required/>
                    </div>
                    <div className="mt-2">
                        <input type="text" name="name" id="name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="E-mail*" required/>
                    </div>
                    <div className="mt-2">
                        <input type="text" name="name" id="name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Whatsapp*" required/>
                        <p className="text-xxs text-gray-400 text-end mt-1">*Dados Obrigatórios</p>
                    </div>

                    <div className="mt-5 text-end sticky bottom-0 pt-3 border-t bg-white ">
                        <button type="submit" className="btn btn-pri w-1/1">Enviar Indicação <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg></button>
                    </div>
                </div>
            </div>
            <div className='col-b mt-6  hidden md:flex md:justify-between'>

                <div className="flex-auto widget-4">
                </div>
            </div>
        </form>
    </section>;

};


export default FormIndications;


